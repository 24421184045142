// import { reactive } from 'vue';

// export const sharedState = reactive({
//     isCenturion: false
// });

import Vue from 'vue'

export const sharedState = Vue.observable({
    isCenturion: ''
})